<template>
  <div class="main-page__banner-wrapper banner-wrapper-header" :style="sliderStyle">
    <div :class="{ 'main-page__banner-body' : isBannerMargin, 'container' : container }">
      <!--      <BannerSlider-->
      <!--        :id="id"-->
      <!--        :banners="banners"-->
      <!--        :autoplay="autoplay"-->
      <!--        :ratio="ratio"-->
      <!--        :sizes="sizes"-->
      <!--        @changeSlide="onChangeSlide"-->
      <!--      />-->
      <BannerSlickSlider
        :id="id"
        :banners="banners.filter((item) => !!item)"
        :autoplay="autoplay"
        :ratio="ratio"
        :sizes="sizes"
        @changeSlide="onChangeSlide"
      />
    </div>
  </div>
</template>

<script>
// import BannerSlider from '~/components/catalog/banner/Slider.vue';
import BannerSlickSlider from '~/components/catalog/banner/SlickSlider.vue';

export default {
  name: 'MainSlider',
  components: {BannerSlickSlider},
  props: {
    id: {
      type: String,
      required: true,
    },
    banners: {
      type: Array,
      required: true,
    },
    autoplay: {
      type: [Object, Boolean],
      default: false,
    },
    ratio: {
      type: Object,
      default() {
        return {}
      },
    },
    sizes: {
      type: Object,
      default() {
        return {}
      },
    },
    container: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isBannerMargin: !this.banners[0]?.color,
      activeSlide: 0,
    }
  },
  computed: {
    sliderStyle() {
      if (!this.banners.filter((item) => !!item).length) return


      const { color, controls_color: controls } = this.banners.filter((item) => !!item)[this.activeSlide] ?? {};

      return `background-color: ${color || 'transparent' };--slider-controls-color: ${controls || '#000'};`
    },
  },
  methods: {
    onChangeSlide(index) {
      this.activeSlide = index
    },
  },
}
</script>

