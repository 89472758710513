<template>
  <a v-if="isExternalLink" :href="to" target="_blank" rel="noopener noreferrer" v-bind="$attrs">
    <slot />
  </a>
  <NuxtLink v-else :to="to" v-bind="$attrs">
    <slot />
  </NuxtLink>
</template>

<script>
export default {
  name: 'CustomLink',
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
  },
  computed: {
    isExternalLink() {
      return typeof this.to === 'string' && this.to.startsWith('http')
    },
  },
}
</script>
